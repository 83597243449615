"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NOTAMScope = exports.NOTAMPurposePriority = exports.NOTAMPurpose = exports.NOTAMTraffic = exports.GFARegion = exports.GFAType = exports.UserTier = exports.STATION_TYPES = exports.STATION_TYPE_OTHER = exports.STATION_TYPE_FIR = exports.STATION_TYPE_AIRPORT = exports.NOTAM_PERMANENT_MARKER = exports.NOTAM_TYPES = exports.NOTAM_TYPE_OTHER = exports.NOTAM_TYPE_FIR = exports.NOTAM_TYPE_AREA = exports.NOTAM_TYPE_NATIONAL = exports.NOTAM_TYPE_AERODROME = void 0;
exports.NOTAM_TYPE_AERODROME = "aerodrome";
exports.NOTAM_TYPE_NATIONAL = "national";
exports.NOTAM_TYPE_AREA = "area";
exports.NOTAM_TYPE_FIR = "FIR";
exports.NOTAM_TYPE_OTHER = "other";
exports.NOTAM_TYPES = [
    exports.NOTAM_TYPE_AERODROME,
    exports.NOTAM_TYPE_NATIONAL,
    exports.NOTAM_TYPE_AREA,
    exports.NOTAM_TYPE_FIR,
    exports.NOTAM_TYPE_OTHER,
];
exports.NOTAM_PERMANENT_MARKER = "PERM";
exports.STATION_TYPE_AIRPORT = "airport";
exports.STATION_TYPE_FIR = "FIR";
exports.STATION_TYPE_OTHER = "other";
exports.STATION_TYPES = [
    exports.STATION_TYPE_AIRPORT,
    exports.STATION_TYPE_FIR,
    exports.STATION_TYPE_OTHER,
];
var UserTier;
(function (UserTier) {
    UserTier[UserTier["Basic"] = 0] = "Basic";
    UserTier[UserTier["Paid"] = 1] = "Paid";
})(UserTier || (exports.UserTier = UserTier = {}));
var GFAType;
(function (GFAType) {
    GFAType["Cloud"] = "CLDWX";
    GFAType["Icing"] = "TURBC";
})(GFAType || (exports.GFAType = GFAType = {}));
var GFARegion;
(function (GFARegion) {
    GFARegion["Pacific"] = "GFACN31";
    GFARegion["Prairies"] = "GFACN32";
    GFARegion["Ont_Que"] = "GFACN33";
    GFARegion["Atlantic"] = "GFACN34";
    GFARegion["Yukon"] = "GFACN35";
    GFARegion["Nunavut"] = "GFACN36";
    GFARegion["Arctic"] = "GFACN37";
})(GFARegion || (exports.GFARegion = GFARegion = {}));
var NOTAMTraffic;
(function (NOTAMTraffic) {
    NOTAMTraffic["IFR"] = "I";
    NOTAMTraffic["VFR"] = "V";
    NOTAMTraffic["EITHER"] = "IV";
    NOTAMTraffic["CHECKLIST"] = "K";
})(NOTAMTraffic || (exports.NOTAMTraffic = NOTAMTraffic = {}));
var NOTAMPurpose;
(function (NOTAMPurpose) {
    NOTAMPurpose["IMMEDIATE"] = "NBO";
    NOTAMPurpose["PIB"] = "BO";
    NOTAMPurpose["OPERATIONAL"] = "O";
    NOTAMPurpose["MISCELLANEOUS"] = "M";
    NOTAMPurpose["CHECKLIST"] = "K";
})(NOTAMPurpose || (exports.NOTAMPurpose = NOTAMPurpose = {}));
exports.NOTAMPurposePriority = {
    [NOTAMPurpose.IMMEDIATE]: 0,
    [NOTAMPurpose.PIB]: 1,
    [NOTAMPurpose.OPERATIONAL]: 2,
    [NOTAMPurpose.MISCELLANEOUS]: 3,
    [NOTAMPurpose.CHECKLIST]: 4,
};
var NOTAMScope;
(function (NOTAMScope) {
    NOTAMScope["AERODROME"] = "A";
    NOTAMScope["ENROUTE"] = "E";
    NOTAMScope["NAV_WARNING"] = "W";
    NOTAMScope["AERODROME_ENROUTE"] = "AE";
    NOTAMScope["AERODROME_NAV_WARNING"] = "AW";
    NOTAMScope["ENROUTE_NAV_WARNING"] = "EW";
    NOTAMScope["CHECKLIST"] = "K";
})(NOTAMScope || (exports.NOTAMScope = NOTAMScope = {}));
