import React, { Component } from "react";
import GFA from "./gfa";
import StationNav from "./stationNav";
import Metars from "./metars";
import Tafs from "./tafs";
import Notams from "./notams";
import Timestamp from "./timestamp";
import {
  Airport,
  NOTAM,
  NOTAMType,
  NOTAM_TYPE_AERODROME,
  NOTAM_TYPE_AREA,
  NOTAM_TYPE_FIR,
  NOTAM_TYPE_NATIONAL,
  NOTAM_TYPE_OTHER,
  Station,
  ValidIATACode,
  ValidICAOCode,
  isAirport,
  isCanadianAirport,
  isIATACode,
  isICAOCode,
} from "@weatheredstrip/shared";

interface PropsType {
  data: Station[];
  search: string[];
  timestamp: Date | null;
}

interface StateType {
  stationSelection: ValidICAOCode | ValidIATACode | undefined;
  notamType: NOTAMType;
}

class Content extends Component<PropsType, StateType> {
  constructor(props: PropsType) {
    super(props);

    const matchingFirstAirport = props.data.find((station) => {
      if (
        props.search.length > 0 &&
        (isICAOCode(props.search[0]) || isIATACode(props.search[0]))
      ) {
        return isAirport(station) && station.codes.includes(props.search[0]);
      }
      return false;
    })?.codes[0] as ValidICAOCode | ValidIATACode;

    this.state = {
      stationSelection: matchingFirstAirport,
      notamType: NOTAM_TYPE_AERODROME,
    };
  }

  onNotamTypeSelection = (type: NOTAMType) => {
    this.setState({ notamType: type });
  };

  handleStationSelect = (value: ValidICAOCode | ValidICAOCode) => {
    this.setState({ stationSelection: value });
  };

  errorExists = () => {
    const { data } = this.props;

    if (this.state.stationSelection && data[this.state.stationSelection]) {
      return data[this.state.stationSelection].ERROR ? true : false;
    } else {
      return false;
    }
  };

  render() {
    const { data } = this.props;

    let selectedData: NOTAM[] | null = null;
    let lengths: Record<NOTAMType, number | null> = {
      [NOTAM_TYPE_AERODROME]: null,
      [NOTAM_TYPE_AREA]: null,
      [NOTAM_TYPE_FIR]: null,
      [NOTAM_TYPE_OTHER]: null,
      [NOTAM_TYPE_NATIONAL]: null,
    };

    const selected = this.state.stationSelection;

    const numberOfAirports = data.filter((station) =>
      isAirport(station)
    ).length;

    if (numberOfAirports === 0) {
      return (
        <div className="content">
          <div className="user-content">
            <div className="content-header">
              <div className="station-name">No valid airport code found.</div>
            </div>
            <div className="selected-content">
              <div id="notams">
                <div id="notam-header">
                  <div className="subtitle">
                    The provided airport codes where not found.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }

    const stationData = data.find(
      (station) =>
        isAirport(station) &&
        selected !== undefined &&
        station.codes.includes(selected)
    ) as Airport;

    const gpsNotams = data.filter(
      (station) => station.codes.indexOf("KGPS" as ValidICAOCode) >= 0
    )[0].notam_EN;

    if (stationData.notam_EN) {
      const stationAerodrome = stationData.notam_EN.filter(
        (notam) => notam.type === "aerodrome"
      );

      let stationFIRNotams: NOTAM[] | null = [];

      if (isAirport(stationData)) {
        stationFIRNotams = isCanadianAirport(stationData)
          ? stationData.notam_EN.filter((notam) => notam.type === "FIR")
          : data.filter(
              (firStation) => firStation.codes[0] === stationData.FIR
            )[0].notam_EN;

        if (isCanadianAirport(stationData) && stationFIRNotams?.length === 0) {
          const potentialNOTAMs = data.filter(
            (firStation) => firStation.codes[0] === stationData.FIR
          )[0]?.notam_EN;

          if (potentialNOTAMs && potentialNOTAMs.length > 0) {
            stationFIRNotams = potentialNOTAMs;
          }
        }
      }

      const stationArea = stationData.notam_EN.filter(
        (notam) => notam.type === "area"
      );
      const stationNational = stationData.notam_EN.filter(
        (notam) => notam.type === "national"
      );
      lengths = {
        [NOTAM_TYPE_AERODROME]: stationAerodrome
          ? stationAerodrome.length
          : null,
        [NOTAM_TYPE_AREA]: stationArea ? stationArea.length : null,
        [NOTAM_TYPE_FIR]: stationFIRNotams ? stationFIRNotams.length : null,
        [NOTAM_TYPE_OTHER]: gpsNotams ? gpsNotams.length : null,
        [NOTAM_TYPE_NATIONAL]: stationNational ? stationNational.length : null,
      };

      switch (this.state.notamType) {
        case NOTAM_TYPE_OTHER:
          selectedData = gpsNotams;
          break;
        case NOTAM_TYPE_FIR:
          selectedData = stationFIRNotams;
          break;
        case NOTAM_TYPE_AERODROME:
          selectedData = stationAerodrome;
          break;
        case NOTAM_TYPE_AREA:
          selectedData = stationArea;
          break;
        case NOTAM_TYPE_NATIONAL:
          selectedData = stationNational;
          break;
        default:
          selectedData = gpsNotams;
      }
    }

    function renderStationName(): React.ReactNode {
      const maxNameLength = 35;
      const reducedStationName =
        stationData.name.length > maxNameLength
          ? stationData.name.substring(0, maxNameLength + 1) + "..."
          : stationData.name;
      return `${stationData.codes[0]} - ${reducedStationName}`;
    }

    return (
      <div className="content">
        {selected !== undefined && (
          <StationNav
            data={data}
            selected={selected}
            onClick={this.handleStationSelect}
          />
        )}
        <div className="user-content">
          {this.errorExists() ? (
            <>
              <div className="content-header">
                <div className="station-name">Error...</div>
              </div>
              <div className="selected-content">
                <div id="notams">
                  <div id="notam-header">
                    <div className="subtitle">
                      This ICAO identifier does not seem to be valid or is not
                      recognized by the server.
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="content-header">
                <div className="station-name">{renderStationName()}</div>
                {this.props.timestamp && (
                  <Timestamp dataTime={this.props.timestamp} />
                )}
              </div>
              <div className="selected-content">
                <div className="metar-rvr">
                  <GFA data={stationData} />
                  <div className="col">
                    <div>
                      <div className="subtitle">METAR</div>
                      <Metars data={stationData.metar} />
                    </div>
                    <div>
                      <div className="subtitle">TAF</div>
                      <Tafs data={stationData.taf} />
                    </div>
                  </div>
                </div>
                <Notams
                  onTypeSelection={this.onNotamTypeSelection}
                  selectedType={this.state.notamType}
                  dataLength={lengths}
                  data={selectedData}
                />
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

export default Content;
